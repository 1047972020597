@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css");

:root {
  --primary-color: #1a131b;
  --secondary-color: #ffd700;
  --background-color: #1a1a1a;
  --background-color-accent: #212121;
  --light-background: #f0f0f0;
  --text-color: #ffffff;
  --text-color-dark: #000000;
  --accent-color: #6a1e6e;
  --error-color: #ff4136;
  --success-color: #4caf50;
}

body {
  font-family: "Arial", sans-serif;
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
  color: var(--text-color);
}

.app-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

.app-header {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 20px;
  text-align: center;
  border-radius: 8px 8px 0 0;
}

.app-main {
  background-color: var(--background-color-accent);
  padding: 20px;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.recording-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.record-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.record-button:hover {
  background-color: var(--accent-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.record-button i {
  margin-right: 8px;
  font-size: 18px;
}

.record-button.recording {
  background-color: var(--error-color);
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 65, 54, 0.4);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(255, 65, 54, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 65, 54, 0);
  }
}

.recording-duration {
  margin-top: 10px;
  font-size: 14px;
  color: var(--secondary-color);
}

.progress-bar-container {
  width: 100%;
  height: 5px;
  background-color: var(--light-background);
  border-radius: 2.5px;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: var(--secondary-color);
  transition: width 0.5s ease;
}

.progress-time {
  position: absolute;
  top: -20px;
  right: 0;
  font-size: 12px;
  color: var(--secondary-color);
}

.playback-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.audio-player {
  width: 100%;
  margin-top: 10px; /* Add some space above the audio player */
  background-color: var(--primary-color);
  border-radius: 5px;
  position: relative;
}

.audio-player::-webkit-media-controls-panel {
  background-color: var(--primary-color);
}

.audio-player::-webkit-media-controls-current-time-display,
.audio-player::-webkit-media-controls-time-remaining-display,
.audio-player::-webkit-media-controls-timeline {
  display: none !important;
}

.audio-player::-webkit-media-controls-play-button,
.audio-player::-webkit-media-controls-mute-button {
  background-color: var(--secondary-color);
  border-radius: 50%;
}

.volume-indicator {
  width: 4px;
  background-color: var(--secondary-color);
  margin-left: 10px;
  transition: height 0.1s ease;
}

.voice-animation {
  display: flex;
  align-items: flex-end;
  height: 20px;
  margin-left: 10px;
}

.voice-bar {
  width: 3px;
  background-color: var(--secondary-color);
  margin: 0 1px;
  transition: height 0.1s ease;
}

.recordings-grid {
  margin-top: 20px;
}

.recordings-grid h2 {
  color: var(--secondary-color);
  text-align: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 30px;
  margin-top: 20px;
}

.grid-item {
  background-color: var(--primary-color);
  padding: 25px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 80px;
  /* justify-content: space-between; */
  transition: all 0.3s ease;
  overflow: hidden;
}

.grid-item .audio-player {
  width: 100%;
  margin-bottom: 20px;
}

.grid-item p {
  margin: 0;
  font-size: 0.8em;
  color: var(--secondary-color);
}

.note-section {
  margin-top: 10px;
}

.note-edit textarea{
color:black !important;
  padding: 10px;
  border-radius: 5px;
}

.note-input {
  width: 100%;
  height: 100px;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid var(--secondary-color);
  background-color: var(--light-background);
  color: var(--text-color);
  resize: vertical;
}

.note-display {
  margin-bottom: 15px;
  font-style: italic;
  color: var(--secondary-color);
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-note-button,
.save-note-button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.8em;
}

.edit-note-button:hover,
.save-note-button:hover {
  background-color: var(--accent-color);
}

.title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.stylish-button.delete-button {
  background-color: #ff444495;
}

.recording-title {
  margin: 0;
  font-size: 1.2em;
  color: var(--secondary-color);
}

.title-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--secondary-color);
  background-color: var(--light-background);
  color: var(--text-color);
}

.edit-title-button,
.save-title-button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.8em;
  margin-top: 5px;
}

.edit-title-button:hover,
.save-title-button:hover {
  background-color: var(--accent-color);
}

@media (max-width: 1200px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 600px) {
  .app-container {
    padding: 10px;
  }

  .grid-item {
    padding: 15px;
  }
}

.audio-container {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.progress-bar {
  flex-grow: 1;
  height: 5px;
  background-color: var(--light-background);
  cursor: pointer;
  margin: 0 10px;
  position: relative;
  min-width: 100px;
  z-index: 1;
}

.progress {
  height: 100%;
  background-color: var(--secondary-color);
  transition: width 0.1s linear;
  pointer-events: none;
}

.audio-player::-webkit-media-controls-panel {
  background-color: var(--primary-color);
}

.audio-player::-webkit-media-controls-current-time-display,
.audio-player::-webkit-media-controls-time-remaining-display,
.audio-player::-webkit-media-controls-timeline {
  display: none !important;
}

.audio-player::-webkit-media-controls-play-button,
.audio-player::-webkit-media-controls-mute-button {
  background-color: var(--secondary-color);
  border-radius: 50%;
}

.custom-audio-player {
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  padding: 15px;
  border-radius: 30px;
  width: 100%;
}

.play-pause-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.play-pause-btn:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.play-pause-btn i {
  color: var(--primary-color);
  font-size: 18px;
  transition: all 0.3s ease;
}

.play-pause-btn.playing {
  background-color: #ff6b6b;
}

.play-pause-btn.playing i {
  color: white;
}

.progress-bar {
  flex-grow: 1;
  height: 8px;
  background-color: var(--light-background);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: var(--secondary-color);
  border-radius: 4px;
  transition: width 0.1s linear;
}

.volume-control {
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-shrink: 0;
  width: 100px;
}

.volume-control i {
  color: var(--secondary-color);
  margin-right: 5px;
}

.volume-slider {
  -webkit-appearance: none;
  width: 70px;
  height: 5px;
  background: var(--light-background);
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.volume-slider:hover {
  opacity: 1;
}

.volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: var(--secondary-color);
  cursor: pointer;
  border-radius: 50%;
}

.volume-slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: var(--secondary-color);
  cursor: pointer;
  border-radius: 50%;
}

.logout-button {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 20px;
  right: 20px;
}

.logout-button:hover {
  background-color: var(--accent-color);
  color: var(--text-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.logout-button i {
  margin-right: 5px;
}

.toggle-auth-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
}

.toggle-auth-button:hover {
  background-color: var(--accent-color);
}

.save-button-recording {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
}

.save-button:hover {
  background-color: #ffdf33;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

.save-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.save-button i {
  margin-right: 8px;
  font-size: 18px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  margin: 15px 0;
  width: 100%;
}

.stylish-button {
  min-width: 180px;
  height: 36px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  /* background-color: var(--light-background); */
  background-color: var(--primary-color);
  border: 1px solid var(--secondary-color);
  color: var(--text-color);
  transition: all 0.2s ease;
}

.stylish-button:hover {
  background-color: var(--accent-color);
  border: 1px solid #3d0c3f;
  transform: translateY(-1px);
}

.stylish-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  transform: none;
}

.stylish-button i {
  font-size: 14px;
}

.download-button {
  /* order: 1; */
}

.delete-button,
.noise-removal-button,
.options-dropdown {
  /* flex: 1 1 auto; */
}

.options-menu {
  width: 200px; /* Adjust as needed */
}

.options-menu button {
  width: 100%;
  text-align: left;
  padding: 10px 15px;
}

.options-dropdown {
  position: relative;
  display: inline-block;
  min-width: 180px;
}

.options-button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.options-button:hover {
  background-color: var(--accent-color);
}

.options-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--primary-color);
  width: 100%;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  margin-top: 5px;
}

.options-menu button {
  width: 100%;
  height: 36px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.options-menu button:hover {
  background-color: var(--primary-color);
}

/* Style for the message popup */
.message-popup {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  color: var(--text-color);
  font-weight: bold;
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out;
}

.message-popup.success {
  background-color: var(--success-color);
}

.message-popup.error {
  background-color: var(--error-color);
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  10%,
  90% {
    opacity: 1;
  }
}

/* Adjust the button group to accommodate the new options button */
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  flex-wrap: wrap;
  gap: 10px;
}

.stylish-button {
  /* min-width: 100px; */
}

@media (max-width: 768px) {
  .recover-button {
    top: -20px !important;
    right: -20px !important;
  }

  .grid-item {
    /* min-height: 350px; */
  }

  .button-group {
    flex-direction: column;
  }

  .stylish-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .delete-button {
    order: 1;
    align-self: center;
    margin-left: 0;
  }

  .options-dropdown {
    width: 100%;
  }

  .options-button {
    width: 100%;
  }

  .options-menu {
    width: 100%;
  }
}

.summarize-button,
.social-content-button {
  background-color: var(--success-color);
  color: var(--text-color);
}

.summarize-button:hover,
.social-content-button:hover {
  background-color: var(--accent-color);
}

.summary,
.social-content {
  margin-top: 15px;
  padding: 10px;
  background-color: var(--light-background);
  border-radius: 5px;
}

.summary h4,
.social-content h4 {
  color: var(--secondary-color);
  margin-bottom: 5px;
}

.summary p,
.social-content p {
  color: var(--text-color);
  font-size: 0.9em;
}

.social-content {
  margin-top: 15px;
  padding: 10px;
  background-color: var(--light-background);
  border-radius: 5px;
}

.social-content h4 {
  color: var(--secondary-color);
  margin-bottom: 10px;
}

.social-content audio {
  width: 100%;
  margin-top: 10px;
}

.ai-options-button,
.social-options-button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.ai-options-button:hover,
.social-options-button:hover {
  background-color: var(--accent-color);
}

.options-dropdown {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.options-menu {
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
}

.options-menu button {
  color: var(--secondary-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
}

.options-menu button:hover {
  background-color: var(--primary-color);
}

.options-menu button:disabled {
  color: #999;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .options-dropdown {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .ai-options-button,
  .social-options-button {
    width: 100%;
  }

  .options-menu {
    width: 100%;
  }
}

.profile-button,
.subscribe-now-button {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.profile-button:hover,
.subscribe-now-button:hover {
  background-color: var(--accent-color);
  color: var(--text-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.profile-button i,
.subscribe-now-button i {
  margin-right: 8px;
}

.profile-button {
  position: absolute;
  top: 20px;
  right: 140px; /* Adjust this value to position it next to the logout button */
}

.subscribe-now-button {
  margin-top: 10px;
}

/* Update the logout button to match the new style */
.logout-button {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 20px;
  right: 20px;
}

.logout-button:hover {
  background-color: var(--accent-color);
  color: var(--text-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.free-trial-message {
  background-color: var(--accent-color);
  color: var(--text-color);
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.free-trial-message p {
  margin-bottom: 10px;
  font-size: 16px;
}

.subscribe-now-button {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.subscribe-now-button:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.subscribe-now-button i {
  margin-right: 8px;
}

.grid-item {
  transition: all 0.3s ease;
  overflow: hidden;
}

.grid-item.collapsed {
  max-height: 80px;
  padding: 15px 25px;
}

.grid-item.expanded {
  min-height: 400px; /* Adjust based on your needs */
  max-height: 800px; /* Adjust based on your needs */
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.grid-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
}

.grid-item-header h3 {
  margin: 0;
}

.grid-item-content {
  transition: all 0.3s ease;
  opacity: 1;
}

.grid-item.collapsed .grid-item-content {
  opacity: 0;
  height: 0;
}

.toggle-icon {
  transition: transform 0.3s ease;
  padding: 10px;
}

.toggle-icon.expanded {
  transform: rotate(180deg);
}

.accordion-section {
  margin: 10px 0;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--primary-color);
}

.section-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  cursor: pointer;
  background-color: var(--primary-color);
  border: 1px solid var(--secondary-color);
  transition: background-color 0.3s ease;
}

.section-header:hover {
  background-color: var(--accent-color);
}

.section-header .header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.section-header h4 {
  margin: 0;
  color: var(--secondary-color);
  font-size: 1rem;
}

.section-header p {
  width: 100%;
  color: var(--text-color);
  font-size: 0.9rem;
  line-height: 1.5;
  text-align: left;
  margin: 0;
}

.section-content {
  padding: 15px;
  background-color: var(--primary-color);
  transition: all 0.3s ease;
  max-height: 1000px;
  opacity: 1;
}

.section-content.collapsed {
  max-height: 0;
  opacity: 0;
  padding: 0 15px;
  overflow: hidden;
}

.section-content p {
  margin: 0;
  color: var(--text-color);
  line-height: 1.5;
}

/* Reuse the existing toggle-icon styles for consistency */
.section-header .toggle-icon {
  transition: transform 0.3s ease;
}

.section-header .toggle-icon.expanded {
  transform: rotate(180deg);
}

.social-post-content {
  background-color: var(--primary-color);
  padding: 15px;
  border-radius: 8px;
}

.social-post-content p {
  margin: 0;
  white-space: pre-wrap;
  font-size: 0.95rem;
}

.section-header h4 i {
  margin-right: 8px;
  font-size: 1.1rem;
}

.fa-twitter {
  color: #1da1f2;
}

.fa-linkedin {
  color: #0077b5;
}

/* Improve accordion spacing */
.accordion-section + .accordion-section {
  margin-top: 15px;
}

.editable-title {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.edit-icon {
  font-size: 0.8em;
  opacity: 0;
  transition: opacity 0.2s ease;
  color: var(--secondary-color);
}

.editable-title:hover .edit-icon {
  opacity: 1;
}

.title-input {
  background-color: var(--light-background);
  border: 1px solid var(--secondary-color);
  color: var(--text-color);
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 1rem;
  width: 80%;
}

.title-input:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(106, 30, 110, 0.2);
}

.title-section {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.microphone-select {
  margin-bottom: 1rem;
  width: 100%;
  max-width: 300px;
}

.device-select {
  width: 100%;
  padding: 12px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  color: var(--background-color);
}

.device-select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.recent-recording-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
  position: relative;
}

.save-button-recording {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
}

.download-button-recording {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.2s;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.download-button-recording:hover {
  background-color: var(--accent-color);
  color: var(--text-color);
}

.download-button-recording i {
  font-size: 14px;
}

.recover-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
  gap: 8px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}

.recover-button:hover {
  background-color: #5a6268;
  transform: translateY(-1px);
}

.recover-button i {
  font-size: 14px;
}

.recover-button:active {
  transform: translateY(0);
}

.voice-recorder-container {
  position: relative;
  padding-top: 60px; /* Add space for the absolute positioned button */
  width: 100%;
}

.free-trial-message + .recording-section .recover-button {
  top: 80px; /* Adjust this value based on the height of the free trial message */
}

.free-trial-message {
  position: relative;
  z-index: 5;
}
