.create-user-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--primary-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.create-user-container h2 {
  color: var(--secondary-color);
  text-align: center;
  margin-bottom: 20px;
}

.create-user-container form {
  display: flex;
  flex-direction: column;
}

.create-user-container input {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid var(--secondary-color);
  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: 5px;
}

.create-user-container .create-user-button {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
}

.create-user-container .create-user-button:hover {
  background-color: var(--accent-color);
}

.error-message {
  color: #ff4136;
  text-align: center;
  margin-bottom: 15px;
}