.dashboard-container {
  padding: 2rem;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.dashboard-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.header-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.last-updated {
  font-size: 0.875rem;
  color: #666;
}

.refresh-button {
  padding: 0.5rem 1rem;
  background-color: var(--light-background);
  color: var(--text-color-dark);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.refresh-button:hover {
  background-color: var(--light-background);
}

.refresh-button:disabled {
  background-color: var(--light-background);
  cursor: not-allowed;
}

.dashboard-table-container {
  background-color: var(--light-background);
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}
td, th {
    color: var(--primary-color);
}

.table-wrapper {
  overflow-x: auto;
}

.dashboard-table {
  width: 100%;
  border-collapse: collapse;
}

.dashboard-table th {
  background-color: var(--light-background);
  padding: 0.75rem 1rem;
  text-align: left;
  font-weight: 600;
}

.dashboard-table td {
  padding: 0.75rem 1rem;
  border-top: 1px solid var(--light-background);
}

.dashboard-table tr:hover {
  background-color: var(--light-background);
}

.status-badge {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.875rem;
}

.status-badge.pro {
  background-color: var(--light-background);
  color: #166534;
}

.status-badge.free {
  background-color: var(--light-background);
  color: var(--text-color-dark);
}

.button-group {
  display: flex;
  gap: 0.5rem;
  justify-content: space-evenly;
  width: 100%;
}

.save-button,
.cancel-button,
.edit-button {
  padding: 8px 16px;
  background-color: var(--light-background);
  color: var(--text-color-dark);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
  height: 36px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}

.cancel-button {
  width: 150px;
  font-size: 12px;
}
.save-button:hover,
.cancel-button:hover,
.edit-button:hover {
  background-color: var(--light-background);
}

.edit-input {
  width: 100%;
  padding: 0.375rem 0.5rem;
  border: 1px solid var(--light-background);
  border-radius: 4px;
}

.edit-select {
  padding: 0.375rem 0.5rem;
  border: 1px solid var(--light-background);
  border-radius: 4px;
}

.name-inputs {
  display: flex;
  gap: 0.5rem;
}

.name-inputs input {
  width: 50%;
}

.usage-info {
  font-size: 0.875rem;
}

.empty-message {
  text-align: center;
  padding: 2rem 0;
  color: #6b7280;
}

.center-align {
  text-align: center;
}

.loading {
  text-align: center;
  padding: 2rem;
  color: #6b7280;
} 