
body {
  background-color: var(--background-color);
  color: var(--text-color);
}

.my-profile-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--background-color);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(138, 43, 226, 0.2);
}

.profile-title {
  text-align: center;
  color: var(--secondary-color);
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.profile-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.profile-form {
  display: grid;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: var(--secondary-color);
}

.form-group input {
  padding: 0.5rem;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  font-size: 1rem;
  background-color: var(--background-color);
  color: var(--text-color);
}

.edit-button, .save-button, .subscribe-button, .feature-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.edit-button:hover, .save-button:hover, .subscribe-button:hover, .feature-button:hover {
  transform: scale(1.05);
}

.edit-button {
  background-color: var(--primary-color);
  color: var(--text-color);
}

.save-button {
  background-color: var(--secondary-color);
  color: var(--background-color);
}

.subscribe-button {
  background-color: var(--secondary-color);
  color: var(--background-color);
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border: 2px solid var(--secondary-color);
  transition: all 0.3s ease;
}

.subscribe-button:hover {
  background-color: transparent;
  color: var(--secondary-color);
}

.feature-button {
  background-color: var(--primary-color);
  color: var(--text-color);
}

.subscription-section, .pro-features {
  background-color: var(--primary-color);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px #1a131b44;
}

.pro-status, .subscription-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.pro-badge {
  align-self: flex-start;
  background-color: var(--secondary-color);
  color: var(--background-color);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-weight: bold;
}

.trial-info {
  margin: 1rem 0;
}

.used {
  color: var(--secondary-color);
}

.available {
  color: var(--primary-color);
}

.attempts {
  font-weight: bold;
  color: var(--secondary-color);
}

.feature-buttons {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.loading {
  text-align: center;
  font-size: 1.5rem;
  color: var(--secondary-color);
  margin-top: 2rem;
}

.form-group input:disabled {
  background-color: var(--accent-color);
  cursor: not-allowed;
}

.form-group input:enabled {
  background-color: var(--background-color);
  cursor: text;
}

.cancel-button {
  background-color: var(--primary-color);
  color: var(--text-color);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.1s ease;
}


.cancel-button:hover {
  background-color: var(--accent-color);
  transform: scale(1.05);
}

.upgrade-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}